import React, {Component} from "react"
import get from 'lodash/get'
import { Link } from "gatsby"
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Img from "gatsby-image"


import Video from '@components/video/video';
import NoVideo from '@components/video/without-video';
import ModalVideo from '@components/video/video-modal';

class LoadMore extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      visible: 9,
      error: false
    };

    this.loadMore = this.loadMore.bind(this);
  }

  loadMore() {
    this.setState((prev) => {
      return {visible: prev.visible + 6};
    });
  }


  componentDidMount() {

    fetch("").then(
      res => this.props.content
    ).then(res => {
    console.log(res);
      this.setState({
        items: res
      });
    }).catch(error => {
      console.error(error);
      this.setState({
        error: true
      });
    });

  }

  render() {

    return (
      <>

        <Row className='mx-md-n4 all-news-list'>
          {this.state.items.slice(0, this.state.visible).map(({node}, index) => {
              return (
                    
                  <Col className="px-md-4 news-tile-box" lg={4} md={6}>
                    <div className='property'>
                      <div className="news__image">

                        {node.Upload_Video != null && node.Upload_Video != "#" &&
                          <Video 
                            image={node.Tile_Image} 
                            video={node.Upload_Video.publicURL}
                            width="100%"
                            contain={true}
                            imgsize="400x300"
                            height="300px"
                          />
                        }
                        {node.Embed_Video_URL != null && node.Embed_Video_URL != "#" &&
                          <ModalVideo 
                            image={node.Tile_Image} 
                            video={node.Embed_Video_URL}
                            name={node.Title}
                            width="100%"
                            contain={true}
                            imgsize="400x300"
                            height="300px"
                          />
                        }
                        {node.Upload_Video == null && node.Embed_Video_URL == null &&
                          <NoVideo 
                            image={node.Tile_Image} 
                            width="100%"
                            contain={true}
                            imgsize="400x300"
                            height="300px"
                          />
                        }




                      </div>
                      {this.props.Level == 2 &&
                      <Link to={`../../${node.news_category.URL}/${node.URL}`}>
                        <h5>{node.Title}</h5>
                        <p>{node.Date}</p>
                      </Link>
                      }
                      {this.props.Level != 2 &&
                      <Link to={`../${node.news_category.URL}/${node.URL}`}>
                        <h5>{node.Title}</h5>
                        <p>{node.Date}</p>
                      </Link>
                      }
                    </div>
                  </Col>

              );
            })}
        </Row>


          {this.state.visible < this.state.items.length &&


            <Row>
              <Col lg={12} className="text-center mb-5">
                  <button onClick={this.loadMore} type="button" className="btn btn-link text-uppercase load-more-pagination-button">{this.props.LoadMore}</button>
              </Col>
            </Row>
          }

      </>

    );
  }
}

export default LoadMore


